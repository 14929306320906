const waypoints: [string, string, number, number, number, string][] = [
    ['ALANA', 'F', 21.07, -157.93, 0.0, 'Y'],
    ['BAMBO', 'F', 21.41, -157.51, 0.0, 'Y'],
    ['CKH', 'V', 21.26, -157.7, 0.0, 'Y'],
    ['DOPIE', 'F', 21.54, -157.83, 0.0, 'Y'],
    ['EWABE', 'F', 21.32, -158.04, 0.0, 'Y'],
    ['GECKO', 'F', 21.19, -158.3, 0.0, 'Y'],
    ['GRITL', 'F', 21.32, -157.62, 0.0, 'Y'],
    ['HAUNA', 'F', 21.09, -157.71, 0.0, 'Y'],
    ['HHI', 'N', 21.47, -158.03, 0.0, 'Y'],
    ['HN', 'N', 21.32, -158.04, 0.0, 'Y'],
    ['HNL', 'V', 21.3, -157.93, 0.0, 'Y'],
    ['JULLE', 'F', 20.95, -157.57, 0.0, 'Y'],
    ['KUCHI', 'F', 20.84, -157.85, 0.0, 'Y'],
    ['MELLO', 'F', 21.52, -157.98, 0.0, 'Y'],
    ['MILTI', 'F', 20.87, -158.1, 0.0, 'Y'],
    ['NGF', 'T', 21.45, -157.76, 0.0, 'Y'],
    ['NORBY', 'F', 21.15, -157.52, 0.0, 'Y'],
    ['OPACA', 'F', 20.93, -158.23, 0.0, 'Y'],
    ['OPIHI', 'F', 21.09, -158.0, 0.0, 'Y'],
    ['PALAY', 'F', 21.1, -157.57, 0.0, 'Y'],
    ['SAITO', 'F', 21.74, -158.04, 0.0, 'Y'],
    ['SAKKI', 'F', 20.93, -157.48, 0.0, 'Y'],
    ['SECIL', 'F', 21.2, -157.77, 0.0, 'Y'],
    ['SHIGI', 'F', 21.3, -158.17, 0.0, 'Y'],
    ['YORKI', 'F', 21.63, -158.21, 0.0, 'Y'],
    ['KEAHI', 'F', 20.8, -157.6, 0.0, 'Y'],
    ['KEOLA', 'F', 21.3, -158.49, 0.0, 'Y'],
]

export default waypoints
