const colours = {
    white: '#ffffff',
    black: '#000000',
    blueGrey25: '#fdfdfd',
    blueGrey50: '#ECEFF1',
    blueGrey75: '#dde3e6',
    blueGrey100: '#CFD8DC',
    blueGrey200: '#B0BEC5',
    blueGrey500: '#607D8B',
    blueGrey900: '#263238',
    coolBlue: '#35a7ff',
    coolNavy: '#38618c',
    coolRed: '#ff5964',
    coolYellow: '#ffe74c',
    red50: '#FFEBEE',
    red100: '#FFCDD2',
    red200: '#EF9A9A',
    red300: '#E57373',
    red400: '#EF5350',
    red500: '#F44336',
    red600: '#E53935',
    red700: '#D32F2F',
    red800: '#C62828',
    red900: '#B71C1C',
    redA100: '#FF8A80',
    redA200: '#FF5252',
    redA400: '#FF1744',
    redA700: '#D50000',
    blue50: '#E3F2FD',
    blue100: '#BBDEFB',
    blue200: '#90CAF9',
    blue300: '#64B5F6',
    blue400: '#42A5F5',
    blue500: '#2196F3',
    blue600: '#1E88E5',
    blue700: '#1976D2',
    blue800: '#1565C0',
    blue900: '#0D47A1',
    blueA100: '#82B1FF',
    blueA200: '#448AFF',
    blueA400: '#2979FF',
    blueA700: '#2962FF',
    green50: '#E8F5E9',
    green100: '#C8E6C9',
    green200: '#A5D6A7',
    green300: '#81C784',
    green400: '#66BB6A',
    green500: '#4CAF50',
    green600: '#43A047',
    green700: '#388E3C',
    green800: '#2E7D32',
    green900: '#1B5E20',
    yellow50: '#FFFDE7',
    yellow100: '#FFF9C4',
    yellow200: '#FFF59D',
    yellow300: '#FFF176',
    yellow400: '#FFEE58',
    yellow500: '#FFEB3B',
    yellow600: '#FDD835',
    yellow700: '#FBC02D',
    yellow800: '#F9A825',
    yellow900: '#F57F17',
}

export default colours
